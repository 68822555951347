const awsmobile = {
  Auth: {
    // フェデレーションアイデンティティのID
    identityPoolId: 'ap-northeast-1:84c26e18-9064-4322-ae0d-cc0e13fc7611',
    // リージョン
    region: 'ap-northeast-1',
    // ユーザープールのID
    userPoolId: 'ap-northeast-1_VRv2LOvFb',
    // ユーザープールのウェブクライアントID
    userPoolWebClientId: '3ukqa7nf67vd1esu8u798jvc07',
    // mandatorySignIn: true
  },
  API: {
    endpoints: [
      {
        name: 'order-real-ui-api',
        // eslint-disable-next-line
        endpoint: 'https://api.real.order.genky-aws.com/b1/graphql',
        region: 'ap-northeast-1',
      },
    ],
  },
}
export default awsmobile
